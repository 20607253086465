<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12" class="mt-3" v-if="!imageList">
            <div class="loading-container">
              <div class="loading-bar"></div>
            </div>
          </b-col>
          <b-col v-else style="height: 400px;">
            <ImageViewer :images="this.imageList" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'
import ImageViewer from '@/components/General/ImageViewer'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    ImageViewer
  },
  data() {
    return {
      imageList: []  
    };
  },
  watch: {
    form: function() {
      this.getImages(this.form.value);
    },
  },
  mounted(){
    this.getImages(this.form.value);
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.imagensExamesEssilor,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['destroyMedicalRecord']),
    ...mapActions('attendance/form/imagensExamesEssilor', ['handleProps']),
    async getImages(images){
      const imageList = [];
      for(var x = 0; x < images.length; x++ ){
        const data = await this.api.getDocTemporaryUrl(images[x].path);
        imageList.push(data.data);
      };

      this.imageList = imageList;
    },
    async openFile(opt) {
      if (opt?.path) {
        const loading = this.$loading.show()
        try {
          const { data } = await this.api.getDocTemporaryUrl(opt.path)
          window.open(data, '_blank')
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          loading.hide()
        }
      }
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value = []
    }
  }
}
</script>
<style lang="scss" scoped>
.essilor-tag {
  display: inline-flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--type-active);
  padding: 4px 16px;
  align-items: center;
  gap: 4px;
  border: 1px solid var(--neutral-300);
  border-radius: 100px;
  margin: 0 8px 8px 0;

  &:not(.selected) {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--light-orange-200);
    svg{
      margin-left: 10px;
      width: 20px;
      fill: var(--type-active);
      cursor: pointer;
    }
  }
}

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .loading-bar {
    width: 100%;
    height: 20px;
    background-color: #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }

  .loading-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

</style>
